import React from 'react';
import styled from 'styled-components/macro';

import icon from './icon.svg';

const StyledImg = styled.img`
  height: 1.5rem;
  width: 4.5rem;
`;

const Icon = () => (
  <StyledImg
    src={icon}
    alt="The WarriorJS icon: a sword made of ASCII characters."
  />
);

export default Icon;
