import { setContext } from 'apollo-link-context';

import Auth from 'services/Auth';

const authLink = setContext(async (request, { headers }) => {
  const session = await Auth.getSession();
  if (session) {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${session.idToken.jwtToken}`,
      },
      uri: process.env.REACT_APP_GRAPHQL_AUTHED_ENDPOINT,
    };
  }
});

export default authLink;
