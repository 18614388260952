import 'modern-normalize';
import { createGlobalStyle, css } from 'styled-components/macro';

import { nord0, nord4 } from 'utils/colors';
import { phone, tablet } from 'utils/media';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 18px;

    ${tablet(css`
      font-size: 16px;
    `)}

    ${phone(css`
      font-size: 14px;
    `)}
  }

  body {
    background-color: ${nord0};
    color: ${nord4};
    font-weight: 300;
    height: 100vh;
    line-height: 1.4;
    overflow-y: hidden;
  }

  button,
  input,
  select,
  textarea {
    font-weight: inherit;
    line-height: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
  }

  #root {
    height: 100%;
    width: 100%;
  }
`;

export default GlobalStyle;
