import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  defaultDataIdFromObject,
} from 'apollo-cache-inmemory';

import introspectionQueryResultData from './fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({
  fragmentMatcher,
  cacheRedirects: {
    Query: {
      campaign: (_, { id }, { getCacheKey }) =>
        getCacheKey({ __typename: 'Campaign', id }),
    },
    Campaign: {
      warriorAbilities: (
        { tower: { id: towerId } },
        { levelNumberOrEpic },
        { getCacheKey },
      ) =>
        getCacheKey({
          towerId,
          levelNumberOrEpic,
          __typename: 'WarriorAbilities',
        }),
      latestRun: ({ id: campaignId }, { levelNumberOrEpic }, { getCacheKey }) =>
        getCacheKey({
          campaignId,
          levelNumberOrEpic,
          __typename: 'Run',
        }),
    },
  },
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'Ability':
        return `Ability:${object.name}`;
      case 'Country':
        return `Country:${object.code}`;
      case 'Employer':
        return `Employer:${object.username}`;
      case 'Level':
        return `Level:${object.campaignId}:${object.number}`;
      case 'Run':
        return `Run:${object.campaignId}:${object.levelNumberOrEpic}`;
      case 'Player':
        return `Player:${object.username}`;
      case 'WarriorAbilities':
        return `WarriorAbilities:${object.towerId}:${object.levelNumberOrEpic}`;
      default:
        return defaultDataIdFromObject(object);
    }
  },
});

export default cache;
