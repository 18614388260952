import React from 'react';
import styled, { css, keyframes } from 'styled-components/macro';

import { nord4 } from 'utils/colors';

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const bounce = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1.0);
  }
`;

const Dot = styled.div`
  animation: ${css`
    ${bounce} 1.4s infinite ease-in-out both;
  `};
  background-color: ${nord4};
  border-radius: 100%;
  display: inline-block;
  height: 0.6rem;
  margin: 0.4rem 0;
  width: 0.6rem;
`;

const FirstDot = styled(Dot)`
  animation-delay: -0.32s;
`;

const SecondDot = styled(Dot)`
  animation-delay: -0.16s;
`;

const Spinner = () => (
  <Container>
    <FirstDot />
    <SecondDot />
    <Dot />
  </Container>
);

export default Spinner;
