import React, { useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import { useLocation } from 'react-router-dom';

import Link from 'components/UI/Link';
import MeQuery from 'data/MeQuery';
import Notification from 'components/UI/Notification';
import useCurrentUser from 'hooks/useCurrentUser';
import { isPlayer } from 'utils/roles';

const IncompleteProfileNotification = () => {
  const currentUser = useCurrentUser();

  const { pathname } = useLocation();

  const [read, setRead] = useState(pathname === '/settings');

  useEffect(() => {
    if (!read && pathname === '/settings') {
      setRead(true);
    }
  }, [read, pathname]);

  if (!currentUser || read) {
    return null;
  }

  return (
    <Query query={MeQuery}>
      {({ data }) => {
        if (!data || !data.me) {
          return null;
        }

        const {
          me: { createdAt, updatedAt },
        } = data;

        const profileUpdated = createdAt !== updatedAt;
        if (profileUpdated) {
          return null;
        }

        const benefit = isPlayer(currentUser)
          ? 'improve your chances of matching with companies'
          : "show developers why they'll want to work with you";
        return (
          <Notification dismissible>
            <Link to="/settings">Complete your profile and {benefit}!</Link>
          </Notification>
        );
      }}
    </Query>
  );
};

export default IncompleteProfileNotification;
