import React, { Suspense, lazy } from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import GlobalStyle from 'components/GlobalStyle';
import EmployerRoute from 'components/Routes/Employer';
import IncompleteProfileNotification from 'components/Notifications/IncompleteProfile';
import Layout from 'components/Layout';
import NotFound from './NotFound';
import PendingConnectionsNotification from 'components/Notifications/PendingConnections';
import PlayerRoute from 'components/Routes/Player';
import PrivateRoute from 'components/Routes/Private';
import PublicOnlyRoute from 'components/Routes/PublicOnly';
import RouteTracker from 'components/RouteTracker';
import Spinner from 'components/UI/Spinner';
import client from 'apollo/client';
import { CurrentUserProvider } from 'components/Context/CurrentUser';

const HomeScreen = lazy(() =>
  import(/* webpackChunkName: "home-screen" */ './Home'),
);

const LeaderboardScreen = lazy(() =>
  import(/* webpackChunkName: "leaderboard-screen" */ './Leaderboard'),
);

const UserLoginScreen = lazy(() =>
  import(/* webpackChunkName: "user-login-screen" */ './User/Login'),
);

const UserSignUpScreen = lazy(() =>
  import(/* webpackChunkName: "user-sign-up-screen" */ './User/SignUp'),
);

const UserConfirmScreen = lazy(() =>
  import(/* webpackChunkName: "user-sign-up-screen" */ './User/Confirm'),
);

const UserResetPasswordScreen = lazy(() =>
  import(
    /* webpackChunkName: "user-reset-password-screen" */ './User/ResetPassword'
  ),
);

const CampaignListScreen = lazy(() =>
  import(/* webpackChunkName: "campaign-list-screen" */ './Campaign/List'),
);

const CampaignFormScreen = lazy(() =>
  import(/* webpackChunkName: "campaign-form-screen" */ './Campaign/Form'),
);

const CampaignDetailScreen = lazy(() =>
  import(/* webpackChunkName: "campaign-detail-screen" */ './Campaign/Detail'),
);

const CandidateSearchScreen = lazy(() =>
  import(
    /* webpackChunkName: "candidate-search-screen" */ './Candidate/Search'
  ),
);

const ConnectionListScreen = lazy(() =>
  import(/* webpackChunkName: "connection-list-screen" */ './Connection/List'),
);

const UserSettingsScreen = lazy(() =>
  import(/* webpackChunkName: "user-settings-screen" */ './User/Settings'),
);

const ProfileScreen = lazy(() =>
  import(/* webpackChunkName: "profile-screen" */ './Profile'),
);

const Root = () => (
  <ApolloProvider client={client}>
    <CurrentUserProvider>
      <Router>
        <RouteTracker />
        <GlobalStyle />
        <IncompleteProfileNotification />
        <PendingConnectionsNotification />
        <Layout>
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route exact path="/" component={HomeScreen} />
              <Route path="/leaderboard" component={LeaderboardScreen} />
              <PublicOnlyRoute path="/login" component={UserLoginScreen} />
              <PublicOnlyRoute path="/signup" component={UserSignUpScreen} />
              <PublicOnlyRoute path="/confirm" component={UserConfirmScreen} />
              <Route path="/reset" component={UserResetPasswordScreen} />
              <PlayerRoute
                exact
                path="/campaigns"
                component={CampaignListScreen}
              />
              <PlayerRoute
                allowAnonymous
                path="/campaigns/new"
                component={CampaignFormScreen}
              />
              <PlayerRoute
                allowAnonymous
                path="/campaigns/:campaignId"
                component={CampaignDetailScreen}
              />
              <EmployerRoute
                path="/candidates"
                component={CandidateSearchScreen}
              />
              <PrivateRoute
                path="/connections"
                component={ConnectionListScreen}
              />
              <PrivateRoute path="/settings" component={UserSettingsScreen} />
              <Route path="/:username" component={ProfileScreen} />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Layout>
      </Router>
    </CurrentUserProvider>
  </ApolloProvider>
);

export default Root;
