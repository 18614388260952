import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';

import client from 'apollo/client';
const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID,
});

const createCognitoUser = username =>
  new CognitoUser({
    Username: username,
    Pool: userPool,
  });

const stripTriggerPrefix = errorMessage =>
  errorMessage.replace(
    /^(?:PreSignUp|PostConfirmation) failed with error (.+)\.$/,
    '$1',
  );

const getUserFromToken = token => {
  const encodedPayload = token.split('.')[1];
  const decodedPayload = Buffer.from(encodedPayload, 'base64').toString('utf8');
  const payload = JSON.parse(decodedPayload);
  const {
    'custom:role': role,
    preferred_username: preferredUsername,
  } = payload;
  return {
    role,
    username: preferredUsername,
  };
};

const Auth = {
  listeners: [],
  addListener: listener => {
    Auth.listeners.push(listener);
  },
  removeListener: listener => {
    const listenerIndex = Auth.listeners.indexOf(listener);
    if (listenerIndex !== -1) {
      Auth.listeners.splice(listenerIndex, 1);
    }
  },
  signUp: (username, password, email, role, campaignId) => {
    const attributes = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
      new CognitoUserAttribute({
        Name: 'preferred_username',
        Value: username.toLowerCase(),
      }),
      new CognitoUserAttribute({
        Name: 'custom:role',
        Value: role,
      }),
    ];
    if (campaignId) {
      attributes.push(
        new CognitoUserAttribute({
          Name: 'custom:campaign_id',
          Value: campaignId,
        }),
      );
    }
    return new Promise((resolve, reject) => {
      userPool.signUp(username, password, attributes, null, (err, result) => {
        if (err) {
          return reject(new Error(stripTriggerPrefix(err.message)));
        }

        resolve(result.user);
      });
    });
  },
  confirmSignUp: (username, code) => {
    const user = createCognitoUser(username);
    return new Promise((resolve, reject) => {
      user.confirmRegistration(code, false, (err, result) => {
        if (err) {
          return reject(new Error(stripTriggerPrefix(err.message)));
        }

        resolve(result);
      });
    });
  },
  resetPassword: alias => {
    const user = createCognitoUser(alias);
    return new Promise((resolve, reject) => {
      user.forgotPassword({
        onSuccess: () => resolve(),
        onFailure: err => {
          if (err.code === 'UserNotFoundException') {
            reject(new Error('There is no user with that username/email'));
          }

          reject(new Error('Oops! Something went wrong...'));
        },
      });
    });
  },
  confirmPassword: (alias, password, code) => {
    const user = createCognitoUser(alias);
    return new Promise((resolve, reject) => {
      user.confirmPassword(code, password, {
        onSuccess: () => resolve(),
        onFailure: err => {
          if (err.code === 'CodeMismatchException') {
            reject(new Error('Invalid confirmation code'));
          }

          reject(new Error('Oops! Something went wrong...'));
        },
      });
    });
  },
  logIn: (alias, password) => {
    const user = createCognitoUser(alias);
    const authDetails = new AuthenticationDetails({
      Username: alias,
      Password: password,
    });
    return new Promise((resolve, reject) => {
      user.authenticateUser(authDetails, {
        onSuccess: async () => {
          await Promise.all(Auth.listeners.map(listener => listener()));
          resolve();
        },
        onFailure: () => {
          // Mask error. See: https://forums.aws.amazon.com/thread.jspa?messageID=857478.
          reject(new Error('Incorrect email or password.'));
        },
        newPasswordRequired: () => {
          const newPasswordToken = user.Session;
          resolve(newPasswordToken);
        },
      });
    });
  },
  logOut: async () => {
    const user = userPool.getCurrentUser();
    if (user) {
      user.signOut();

      client.resetStore();

      await Promise.all(Auth.listeners.map(listener => listener()));
    }
  },
  changePassword: (currentPassword, newPassword) => {
    const user = userPool.getCurrentUser();
    if (!user) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      user.getSession(err => {
        if (err) {
          return reject(err);
        }

        user.changePassword(currentPassword, newPassword, (err, result) => {
          if (err) {
            return reject(err);
          }

          resolve(result);
        });
      });
    });
  },
  changeTemporaryPassword: (alias, token, password) => {
    const user = createCognitoUser(alias);
    // This is necessary because we are creating a new cognito user instead of
    // reusing the instance that tried to authenticate.
    user.Session = token;
    return new Promise((resolve, reject) => {
      user.completeNewPasswordChallenge(
        password,
        {},
        {
          onSuccess: async () => {
            await Promise.all(Auth.listeners.map(listener => listener()));
            resolve();
          },
          onFailure: () =>
            reject(
              new Error('Oops! Something went wrong... Please try again.'),
            ),
        },
      );
    });
  },
  getSession: () => {
    const user = userPool.getCurrentUser();
    if (!user) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) {
          return reject(err);
        }

        if (session.isValid()) {
          return resolve(session);
        }

        const refreshToken = session.getRefreshToken();
        user.refreshSession(refreshToken, (err, newSession) => {
          if (err) {
            return reject(err);
          }

          resolve(newSession);
        });
      });
    });
  },
  getCurrentUser: async () => {
    const session = await Auth.getSession();
    if (!session) {
      return null;
    }

    return getUserFromToken(session.idToken.jwtToken);
  },
};

export default Auth;
