import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const isGAEnabled = process.env.NODE_ENV === 'production';
const trackingID = process.env.REACT_APP_GA_TRACKING_ID;

const RouteTracker = () => {
  useEffect(() => {
    if (isGAEnabled) {
      ReactGA.initialize(trackingID);
    }
  }, []);

  const { pathname, search } = useLocation();
  const page = pathname + search;
  useEffect(() => {
    if (isGAEnabled) {
      ReactGA.set({ page });
      ReactGA.pageview(page);
    }
  }, [page]);

  return null;
};

export default RouteTracker;
