import gql from 'graphql-tag';

const ConnectionsQuery = gql`
  query Connections {
    connections {
      id
      username
      preferredUsername
      email
      connection {
        status
        requestedAt
        repliedAt
      }
      ... on Player {
        location {
          code
        }
        technologies {
          id
          name
        }
      }
      ... on Employer {
        companyName
        technologies {
          id
          name
        }
      }
    }
  }
`;

export default ConnectionsQuery;
