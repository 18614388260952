import React from 'react';
import styled, { css } from 'styled-components/macro';

import Link from 'components/UI/Link';
import useCurrentUser from 'hooks/useCurrentUser';
import { isPlayer } from 'utils/roles';
import { nord1, nord13 } from 'utils/colors';
import { phone } from 'utils/media';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 4rem 2rem;
  width: 100%;

  ${phone(css`
    padding-left: 2rem;
    padding-right: 2rem;
  `)};
`;

const Title = styled.h1`
  margin: 0 0 2rem;
`;

const Text = styled.p`
  margin: 0 0 2rem;
  text-align: center;
`;

const LinkList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
`;

const CodeLink = styled(Link)`
  text-decoration-color: ${nord13};

  code {
    background-color: ${nord1};
    border-radius: 3px;
    color: ${nord13};
    padding: 0.1em 0.2em;
  }
`;

const NotFound = () => {
  const currentUser = useCurrentUser();

  return (
    <Container>
      <Title>Oops!</Title>
      <Text>We can't seem to find the page you're looking for.</Text>
      <LinkList>
        <li>
          <CodeLink to="/">
            <code>warrior.goHome()</code>
          </CodeLink>
        </li>
        {currentUser && (
          <li>
            {isPlayer(currentUser) ? (
              <CodeLink to="/campaigns">
                <code>warrior.goPlay()</code>
              </CodeLink>
            ) : (
              <CodeLink to="/candidates">
                <code>warrior.goFindTalent()</code>
              </CodeLink>
            )}
          </li>
        )}
      </LinkList>
    </Container>
  );
};

export default NotFound;
