import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import useCurrentUser from 'hooks/useCurrentUser';
import { isPlayer } from 'utils/roles';

const PlayerRoute = ({ allowAnonymous, component: Component, ...rest }) => {
  const currentUser = useCurrentUser();

  return (
    <Route
      {...rest}
      render={props =>
        (!currentUser && allowAnonymous) ||
        (currentUser && isPlayer(currentUser)) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

PlayerRoute.propTypes = {
  allowAnonymous: PropTypes.bool,
};

PlayerRoute.defaultProps = {
  allowAnonymous: false,
};

export default PlayerRoute;
