import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

import LogoutButton from './LogoutButton';
import useCurrentUser from 'hooks/useCurrentUser';
import { menuIndex } from 'utils/zindex';
import { nord0, nord1, nord4, nord7 } from 'utils/colors';
import { phone } from 'utils/media';

const Container = styled.div`
  position: relative;

  ${phone(css`
    position: static;
  `)};
`;

const User = styled.div`
  align-items: center;
  color: ${nord7};
  cursor: pointer;
  display: flex;
  margin-left: 1em;
  text-transform: uppercase;
`;

const StyledSvg = styled.svg`
  height: 0.7rem;
  margin-left: 0.4rem;
  width: 0.7rem;
`;

const DownArrow = () => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 9">
    <polygon
      fill={nord7}
      points="14 7 12.77 8.232 7 2.462 1.23 8.232 0 7 7 0"
      transform="matrix(1 0 0 -1 0 9)"
    />
  </StyledSvg>
);

const Menu = styled.ul`
  background-color: ${nord0};
  border-radius: 3px;
  border: 1px solid ${nord1};
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 1.5rem;
  width: 10rem;
  z-index: ${menuIndex};

  ${phone(css`
    align-items: center;
    background-color: ${nord1};
    border-radius: none;
    border: none;
    box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.4);
    flex-direction: row;
    justify-content: flex-end;
    left: 0;
    padding-right: 2rem;
    top: 3rem;
    width: 100%;
  `)};
`;

const MenuLink = styled(Link)`
  align-items: center;
  color: ${nord4};
  display: flex;
  padding: 0.6rem 1rem;
  text-decoration: none;
  transition: background 0.2s ease, color 0.2s ease;
  white-space: nowrap;

  &:hover {
    background-color: ${nord1};
    color: ${nord7};
  }

  ${phone(css`
    height: 3rem;
    padding: 0 1rem;
  `)};
`;

const Separator = styled.li`
  margin: 0 1rem;
  border-bottom: 1px solid ${nord1};

  ${phone(css`
    display: none;
  `)};
`;

const UserMenu = () => {
  const [showMenu, setShowMenu] = useState(false);

  const { username } = useCurrentUser();

  const userRef = useRef();

  useEffect(() => {
    const handleClick = e => {
      if (!userRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const handleUserClick = () => {
    setShowMenu(showMenu => !showMenu);
  };

  return (
    <Container>
      <User ref={userRef} onClick={handleUserClick}>
        {username}
        <DownArrow />
      </User>
      {showMenu && (
        <Menu>
          <li>
            <MenuLink title="Your profile" to={`/${username}`}>
              Profile
            </MenuLink>
          </li>
          <li>
            <MenuLink title="Your connections" to="/connections">
              Connections
            </MenuLink>
          </li>
          <Separator />
          <li>
            <MenuLink title="Your account settings" to="/settings">
              Settings
            </MenuLink>
          </li>
          <Separator />
          <li>
            <LogoutButton />
          </li>
        </Menu>
      )}
    </Container>
  );
};

export default UserMenu;
