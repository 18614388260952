import { css } from 'styled-components/macro';

export const TABLET_QUERY = `(max-width: 62em)`;
export const tablet = inner => css`
  @media ${TABLET_QUERY} {
    ${inner};
  }
`;

export const PHONE_QUERY = `(max-width: 36em)`;
export const phone = inner => css`
  @media ${PHONE_QUERY} {
    ${inner};
  }
`;
