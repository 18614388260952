import isEqual from 'react-fast-compare';
import { onError } from 'apollo-link-error';

// Used to ignore errors on mutations caused for requesting unauthorized fields
// on the mutation result.
const ignoreErrorsLink = onError(({ response }) => {
  if (response) {
    response.errors = response.errors.filter(
      err =>
        !isEqual(err.path, ['addConnection', 'email']) ||
        err.extensions.code !== 'FORBIDDEN',
    );
  }
});

export default ignoreErrorsLink;
