import gql from 'graphql-tag';

const MeQuery = gql`
  query Me {
    me {
      username
      preferredUsername
      email
      createdAt
      updatedAt
      ... on Player {
        location {
          code
        }
        availableForHire
        technologies {
          id
        }
      }
      ... on Employer {
        companyName
        technologies {
          id
        }
        billingEmail
      }
    }
  }
`;

export default MeQuery;
