import 'url-search-params-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import viewportUnitsBuggyfill from 'viewport-units-buggyfill';

import Root from './screens/Root';

viewportUnitsBuggyfill.init();

ReactDOM.render(<Root />, document.getElementById('root'));
