import React, { useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import { useLocation } from 'react-router-dom';

import ConnectionsQuery from 'data/ConnectionsQuery';
import Link from 'components/UI/Link';
import Notification from 'components/UI/Notification';
import useCurrentUser from 'hooks/useCurrentUser';
import { isPlayer } from 'utils/roles';

const PendingConnectionsNotification = () => {
  const currentUser = useCurrentUser();

  const { pathname } = useLocation();

  const [read, setRead] = useState(pathname === '/connections');

  useEffect(() => {
    if (!read && pathname === '/connections') {
      setRead(true);
    }
  }, [read, pathname]);

  if (!currentUser || !isPlayer(currentUser) || read) {
    return null;
  }

  return (
    <Query query={ConnectionsQuery}>
      {({ data }) => {
        if (!data || !data.connections) {
          return null;
        }

        const pendingConnections = data.connections.filter(
          ({ connection: { status } }) => status === 'pending',
        );
        const pendingConnectionCount = pendingConnections.length;
        if (pendingConnectionCount === 0) {
          return null;
        }

        let text;
        if (pendingConnectionCount === 1) {
          text = `${pendingConnections[0].companyName} has sent you a connection request!`;
        } else if (pendingConnectionCount === 2) {
          text = `${pendingConnections[0].companyName} and ${pendingConnections[1].companyName} have sent you connection requests!`;
        } else {
          text = `${pendingConnections[0].companyName}, ${pendingConnections[1].companyName}, and other companies have sent you connection requests!`;
        }
        return (
          <Notification dismissible>
            <Link to="/connections">{text}</Link>
          </Notification>
        );
      }}
    </Query>
  );
};

export default PendingConnectionsNotification;
