import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

import Header from './Header';
import { notificationIndex } from 'utils/zindex';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Notifications = styled.div`
  z-index: ${notificationIndex};
`;

const StyledMain = styled.main`
  display: flex;
  flex: 1;
  min-height: 0;
  width: 100%;
`;

const Layout = ({ children }) => (
  <Container>
    <Header />
    <Notifications id="notifications-root" />
    <StyledMain>{children}</StyledMain>
  </Container>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
