import styled, { css } from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';

import { nord7 } from 'utils/colors';

const linkCSS = css`
  color: inherit;
  font-size: 0.9rem;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const primaryLinkCSS = css`
  color: ${nord7};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Link = styled(RouterLink)`
  ${({ primary }) => (primary ? primaryLinkCSS : linkCSS)};
`;

Link.Outbound = styled.a`
  ${({ primary }) => (primary ? primaryLinkCSS : linkCSS)};
`;

export default Link;
