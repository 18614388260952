import React from 'react';
import styled from 'styled-components/macro';
import { NavLink, Route } from 'react-router-dom';

import Icon from 'components/UI/Icon';
import UserMenu from 'components/Layout/UserMenu';
import useCurrentUser from 'hooks/useCurrentUser';
import { isPlayer } from 'utils/roles';
import { noir, nord4, nord7 } from 'utils/colors';

const StyledHeader = styled.header`
  align-items: center;
  background-color: ${noir};
  display: flex;
  font-size: 0.8rem;
  height: 3rem;
  padding: 0 2rem;
`;

const NavBar = styled.nav`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const IconNavLink = styled(NavLink)`
  display: flex;
`;

const HeaderNavLink = styled(NavLink)`
  color: ${({ primary }) => (primary ? nord7 : nord4)};
  margin-left: 1em;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.2s ease;

  &:hover {
    color: ${nord7};
  }

  &.active {
    color: ${nord7};
  }
`;

const Header = () => {
  const currentUser = useCurrentUser();

  return (
    <StyledHeader>
      <IconNavLink exact to="/">
        <Icon />
      </IconNavLink>
      <NavBar>
        {currentUser ? (
          <>
            {isPlayer(currentUser) ? (
              <HeaderNavLink exact to="/campaigns">
                Play
              </HeaderNavLink>
            ) : (
              <HeaderNavLink exact to="/candidates">
                Search
              </HeaderNavLink>
            )}
            <UserMenu />
          </>
        ) : (
          <>
            <HeaderNavLink exact to="/login">
              Login
            </HeaderNavLink>
            <Route path="/campaigns/:campaignId">
              {({ match }) => {
                let signUpUrl = '/signup';
                if (match && match.params.campaignId !== 'new') {
                  signUpUrl += `?campaignId=${match.params.campaignId}`;
                }

                return (
                  <HeaderNavLink exact primary="true" to={signUpUrl}>
                    Sign Up
                  </HeaderNavLink>
                );
              }}
            </Route>
          </>
        )}
      </NavBar>
    </StyledHeader>
  );
};

export default Header;
