import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useHistory } from 'react-router-dom';

import Auth from 'services/Auth';
import { nord1, nord7 } from 'utils/colors';
import { phone } from 'utils/media';

const StyledButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-weight: inherit;
  line-height: inherit;
  padding: 0.6rem 1rem;
  transition: background 0.2s ease, color 0.2s ease;
  white-space: nowrap;
  width: 100%;

  &:hover {
    background-color: ${nord1};
    color: ${nord7};
  }

  &:focus {
    outline: none;
  }

  ${phone(css`
    height: 3rem;
    padding: 0 0 0 1rem;
  `)};
`;

const LogoutButton = () => {
  const history = useHistory();

  const handleLogOutClick = async () => {
    await Auth.logOut();
    history.push('/');
  };

  return (
    <StyledButton title="Log out of WarriorJS" onClick={handleLogOutClick}>
      Log Out
    </StyledButton>
  );
};

export default LogoutButton;
