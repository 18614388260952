import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';

import authLink from './authLink';
import cache from './cache';
import ignoreErrorsLink from './ignoreErrorsLink';
import httpLink from './httpLink';

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([ignoreErrorsLink, authLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
  },
});

export default client;
