import React, { useEffect, useMemo, useState } from 'react';

import Auth from 'services/Auth';

const CurrentUserContext = React.createContext();

export const CurrentUserConsumer = CurrentUserContext.Consumer;

export const CurrentUserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const updateCurrentUser = async () => {
      setCurrentUser(await Auth.getCurrentUser());
    };

    updateCurrentUser();

    Auth.addListener(updateCurrentUser);

    return () => {
      Auth.removeListener(updateCurrentUser);
    };
  }, []);

  const context = useMemo(() => currentUser, [currentUser]);

  if (typeof currentUser === 'undefined') {
    return null;
  }

  return (
    <CurrentUserContext.Provider value={context}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserContext;
