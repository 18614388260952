// Darkest color.
export const noir = '#20232a';

// Base component color of "Polar Night".
export const nord0 = '#2e3440';

// Lighter shade color of the base component color.
export const nord1 = '#3b4252';

// Lighter shade color of the base component color.
export const nord2 = '#434c5e';

// Lighter shade color of the base component color.
export const nord3 = '#4c566a';

// Base component color of "Snow Storm".
export const nord4 = '#d8dee9';

// Lighter shade color of the base component color.
export const nord5 = '#e5e9f0';

// Lighter shade color of the base component color.
export const nord6 = '#eceff4';

// Bluish core color.
export const nord7 = '#8fbcbb';

// Bluish core accent color.
export const nord8 = '#88c0d0';

// Bluish core color.
export const nord9 = '#81a1c1';

// Bluish core color.
export const nord10 = '#5e81ac';

// Colorful component color.
export const nord11 = '#bf616a';

// Colorful component color.
export const nord12 = '#d08770';

// Colorful component color.
export const nord13 = '#ebcb8b';

// Colorful component color.
export const nord14 = '#a3be8c';

// Colorful component color.
export const nord15 = '#b48ead';
