import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import useCurrentUser from 'hooks/useCurrentUser';
import { isEmployer } from 'utils/roles';

const EmployerRoute = ({ component: Component, ...rest }) => {
  const currentUser = useCurrentUser();

  return (
    <Route
      {...rest}
      render={props =>
        currentUser && isEmployer(currentUser) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default EmployerRoute;
