import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import useCurrentUser from 'hooks/useCurrentUser';
import { isPlayer } from 'utils/roles';

const PublicOnlyRoute = ({ component: Component, ...rest }) => {
  const currentUser = useCurrentUser();

  return (
    <Route
      {...rest}
      render={props =>
        currentUser ? (
          <Redirect to={isPlayer(currentUser) ? '/campaigns' : '/candidates'} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicOnlyRoute;
